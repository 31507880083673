<div
  class="mx-auto flex h-[70px] w-full max-w-screen-2xl justify-between bg-white px-6 py-4"
>
  <div class="row flex cursor-pointer gap-2 md:gap-4" [routerLink]="'/'">
    <svg
      width="112.5"
      height="37.35"
      viewBox="0 0 500 166"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Top bar logo</title>
      <path
        d="M0 31.1259C0 13.9356 13.9356 0 31.1259 0H134.879C152.069 0 166.005 13.9356 166.005 31.1259V134.879C166.005 152.069 152.069 166.005 134.879 166.005H31.1259C13.9356 166.005 0 152.069 0 134.879V31.1259Z"
        fill="url(#paint0_linear_47_1232)"
      />
      <path
        d="M0 31.1259C0 13.9356 13.9356 0 31.1259 0H134.879C152.069 0 166.005 13.9356 166.005 31.1259V134.879C166.005 152.069 152.069 166.005 134.879 166.005H31.1259C13.9356 166.005 0 152.069 0 134.879V31.1259Z"
        fill="url(#paint1_linear_47_1232)"
      />
      <g clip-path="url(#clip0_47_1232)">
        <path
          d="M25.7924 76.0936L18.4811 77.2976C17.496 77.4605 16.6005 76.7097 16.6005 75.7253C16.6005 74.7409 17.496 73.9901 18.4811 74.1495L25.7924 75.3535C26.2151 75.4243 26.2151 76.0228 25.7924 76.0936Z"
          fill="#E8B500"
        />
        <path
          d="M28.5902 61.4544L21.3684 59.8007C20.3976 59.5776 19.8603 58.5472 20.24 57.6371C20.6233 56.727 21.741 56.3729 22.5864 56.8934L28.8767 60.771C29.2385 60.9941 29.0093 61.5501 28.5902 61.4544Z"
          fill="#E8B500"
        />
        <path
          d="M36.84 48.9896L30.8076 44.7295C29.9944 44.1559 29.8977 43.0015 30.6034 42.3038C31.3091 41.6062 32.4805 41.7019 33.0572 42.5057L37.3666 48.469C37.6138 48.8125 37.1875 49.2374 36.8364 48.9931L36.84 48.9896Z"
          fill="#E8B500"
        />
        <path
          d="M49.2882 40.5935L45.3657 34.3752C44.8391 33.5395 45.1973 32.4346 46.118 32.0557C47.0386 31.6768 48.0846 32.208 48.3067 33.1712L49.9796 40.3102C50.0763 40.721 49.5139 40.9511 49.2882 40.5935Z"
          fill="#E8B500"
        />
        <path
          d="M64.0398 37.5445L62.8219 30.317C62.6571 29.3432 63.4165 28.4579 64.4159 28.4579C65.4118 28.4579 66.1712 29.3432 66.01 30.317L64.7921 37.5445C64.7204 37.9624 64.115 37.9624 64.0434 37.5445H64.0398Z"
          fill="#E8B500"
        />
        <path
          d="M78.8487 40.3102L80.5216 33.1712C80.7473 32.2116 81.7897 31.6804 82.7103 32.0557C83.631 32.4346 83.9892 33.5395 83.4626 34.3752L79.5401 40.5935C79.3144 40.9511 78.752 40.7245 78.8487 40.3102Z"
          fill="#E8B500"
        />
        <path
          d="M91.4617 48.4655L95.7711 42.5022C96.3514 41.6983 97.5192 41.6027 98.2249 42.3003C98.9306 42.9979 98.8339 44.1559 98.0207 44.726L91.9883 48.986C91.6408 49.2304 91.2109 48.809 91.4581 48.4619L91.4617 48.4655Z"
          fill="#E8B500"
        />
        <path
          d="M66.4041 44.7402C48.2422 44.7402 33.376 58.9615 32.6273 76.724H90.4049C90.7238 71.2777 92.8516 66.3023 96.2046 62.3787C90.4981 51.8898 79.2821 44.7402 66.4041 44.7402Z"
          fill="#E8B500"
        />
        <path
          d="M137.017 76.724C136.272 65.4489 126.747 56.4933 115.151 56.4898H115.14C109.09 56.4898 103.605 58.9261 99.6363 62.8532C98.8948 63.5863 98.2034 64.3724 97.573 65.2046C95.1084 68.4624 93.543 72.4215 93.26 76.724H137.017Z"
          fill="#ABD797"
        />
        <path
          d="M32.6416 79.5427C34.2178 105.485 78.5478 133.676 84.831 137.547C91.1142 133.676 135.444 105.475 137.02 79.5427H32.6416Z"
          fill="#57B030"
        />
      </g>
      <path
        d="M191.224 41.2975C191.224 43.7997 193.867 45.0838 198.512 45.5148C207.874 46.3708 212.66 49.8728 212.66 57.164C212.66 64.4552 206.3 70.0254 196.297 70.0254C187.222 70.0254 179.79 65.3801 180.577 54.2338H189.152C188.724 59.8788 192.011 62.7372 196.369 62.7372C201.158 62.7372 203.373 60.3786 203.373 58.02C203.373 55.2335 201.086 53.8027 196.084 53.3029C186.15 52.375 181.936 48.7294 181.936 42.0129C181.936 33.9374 189.224 30.1511 196.584 30.1511C204.803 30.1511 211.804 34.2248 211.02 43.3717H202.445C202.589 39.4418 200.302 37.4394 196.656 37.4394C193.726 37.4394 191.227 39.0826 191.227 41.2975H191.224Z"
        fill="#103328"
      />
      <path
        d="M257.324 55.1616L263.113 31.1508H273.547L263.113 69.0258H252.322L243.891 43.5154L235.459 69.0258H224.669L214.235 31.1508H224.669L230.458 55.1616L238.246 31.1508H249.536L257.324 55.1616Z"
        fill="#103328"
      />
      <path
        d="M284.408 13.5005C287.767 13.5005 290.481 16.2152 290.481 19.5735C290.481 22.9318 287.767 25.6466 284.408 25.6466C281.05 25.6466 278.335 22.9318 278.335 19.5735C278.335 16.2152 281.05 13.5005 284.408 13.5005ZM279.547 31.1509H289.266V69.0258H279.547V31.1509Z"
        fill="#103328"
      />
      <path
        d="M312.277 39.7984V56.1618C312.277 60.0917 314.279 61.7349 317.778 61.7349C319.493 61.7349 321.424 61.3069 321.996 61.0196L322.067 68.9513C321.208 69.3075 318.922 70.0229 315.923 70.0229C307.919 70.0229 302.702 66.0929 302.702 58.0175V39.7954H295.914V31.1483H302.702V21.5014H312.277V31.1483H322.711V39.7954H312.277V39.7984Z"
        fill="#103328"
      />
      <path
        d="M348.006 61.1658C353.723 61.1658 356.797 57.9512 357.437 54.0182H367.44C366.441 62.378 360.436 70.0254 347.931 70.0254C336.354 70.0254 327.422 62.1655 327.422 50.0883C327.422 38.0111 336.354 30.1511 347.931 30.1511C360.436 30.1511 366.441 37.7985 367.44 46.1583H357.437C356.794 42.2284 353.723 39.0137 348.006 39.0137C341.717 39.0137 337.216 43.3717 337.216 50.0913C337.216 56.8108 341.717 61.1688 348.006 61.1688V61.1658Z"
        fill="#103328"
      />
      <path
        d="M373.732 11.8575H383.451V36.6554C385.881 32.1538 390.024 30.1514 395.456 30.1514C404.888 30.1514 409.964 36.7243 409.964 43.7282V69.026H400.245V46.8739C400.245 42.085 397.387 39.2984 392.457 39.2984C387.168 39.2984 383.454 42.6566 383.454 47.8736V69.026H373.735V11.8575H373.732Z"
        fill="#103328"
      />
      <path
        d="M194.51 109.631V125.994C194.51 129.924 196.513 131.567 200.012 131.567C201.727 131.567 203.657 131.139 204.229 130.852L204.301 138.784C203.442 139.14 201.155 139.855 198.156 139.855C190.152 139.855 184.935 135.925 184.935 127.85V109.628H178.147V100.981H184.935V91.3337H194.51V100.981H204.944V109.628H194.51V109.631Z"
        fill="#103328"
      />
      <path
        d="M209.662 119.921C209.662 107.844 218.022 99.984 229.886 99.984C241.751 99.984 250.111 107.844 250.111 119.921C250.111 131.998 241.751 139.858 229.886 139.858C218.022 139.858 209.662 131.998 209.662 119.921ZM240.174 119.921C240.174 113.348 236.028 109.131 229.883 109.131C223.738 109.131 219.593 113.348 219.593 119.921C219.593 126.494 223.738 130.711 229.883 130.711C236.028 130.711 240.174 126.494 240.174 119.921Z"
        fill="#103328"
      />
      <path
        d="M285.336 132.426C283.122 135.713 278.904 138.143 273.187 138.143C263.684 138.143 255.181 130.427 255.181 119.062C255.181 107.697 263.684 99.981 273.187 99.981C280.048 99.981 284.549 103.411 286.908 108.056V100.981H294.911V137.64C294.911 147.643 286.836 154.147 275.259 154.147C263.681 154.147 256.749 147.931 256.037 139.999H265.468C266.112 143.001 268.611 146.288 275.259 146.288C281.906 146.288 285.333 142.642 285.333 138.499V132.426H285.336ZM274.974 108.772C270.113 108.772 264.899 112.058 264.899 119.062C264.899 126.066 270.116 129.281 274.974 129.281C281.547 129.281 285.336 125.135 285.336 119.062C285.336 112.989 281.55 108.772 274.974 108.772Z"
        fill="#103328"
      />
      <path
        d="M300.416 119.921C300.416 107.772 309.063 99.984 319.566 99.984C330.069 99.984 338.004 107.272 338.004 119.921C338.004 121.28 337.788 122.708 337.647 123.208H310.278C310.778 128.709 314.208 131.998 319.853 131.998C325.498 131.998 327.785 129.427 328.644 126.853H337.719C336.648 133.357 331.574 139.858 319.925 139.858C308.276 139.858 300.416 132.07 300.416 119.921ZM327.998 116.063C327.57 110.702 324.283 107.844 319.279 107.844C314.274 107.844 310.847 110.846 310.419 116.063H327.998Z"
        fill="#103328"
      />
      <path
        d="M356.298 109.631V125.994C356.298 129.924 358.3 131.567 361.799 131.567C363.514 131.567 365.445 131.139 366.016 130.852L366.088 138.784C365.229 139.14 362.942 139.855 359.943 139.855C351.94 139.855 346.723 135.925 346.723 127.85V109.628H339.934V100.981H346.723V91.3337H356.298V100.981H366.732V109.628H356.298V109.631Z"
        fill="#103328"
      />
      <path
        d="M373.589 81.6898H383.308V106.488C385.738 101.986 389.88 99.9837 395.313 99.9837C404.744 99.9837 409.821 106.557 409.821 113.56V138.858H400.102V116.706C400.102 111.917 397.243 109.131 392.314 109.131C387.025 109.131 383.311 112.489 383.311 117.706V138.858H373.592V81.6898H373.589Z"
        fill="#103328"
      />
      <path
        d="M415.465 119.921C415.465 107.772 424.113 99.984 434.615 99.984C445.118 99.984 453.053 107.272 453.053 119.921C453.053 121.28 452.837 122.708 452.697 123.208H425.328C425.828 128.709 429.258 131.998 434.903 131.998C440.548 131.998 442.834 129.427 443.693 126.853H452.769C451.697 133.357 446.624 139.858 434.975 139.858C423.325 139.858 415.465 132.07 415.465 119.921ZM443.047 116.063C442.619 110.702 439.332 107.844 434.328 107.844C429.323 107.844 425.896 110.846 425.468 116.063H443.047Z"
        fill="#103328"
      />
      <path
        d="M479.85 113.633C479.85 110.418 477.991 108.416 475.061 108.416C471.415 108.416 469.2 111.202 469.2 115.132V138.856H459.482V100.981H467.485V108.341C469.129 102.408 473.346 99.981 478.347 99.981C484.064 99.981 489.281 104.554 488.853 113.63H479.85V113.633Z"
        fill="#103328"
      />
      <path
        d="M493.927 127.425C497.285 127.425 500 130.14 500 133.498C500 136.856 497.285 139.571 493.927 139.571C490.569 139.571 487.854 136.856 487.854 133.498C487.854 130.14 490.569 127.425 493.927 127.425Z"
        fill="#103328"
      />
      <defs>
        <linearGradient
          id="paint0_linear_47_1232"
          x1="153.15"
          y1="166.005"
          x2="12.6714"
          y2="0.0771183"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#103328" />
          <stop offset="1" stop-color="#2A6F59" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_47_1232"
          x1="153.15"
          y1="166.005"
          x2="12.6714"
          y2="0.0771183"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#103328" />
          <stop offset="1" stop-color="#2A6F59" />
        </linearGradient>
        <clipPath id="clip0_47_1232">
          <rect
            width="123.318"
            height="109.089"
            fill="white"
            transform="translate(16.6005 28.458)"
          />
        </clipPath>
      </defs>
    </svg>
    <div class="h-[40px] w-px bg-gray-300"></div>
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="60pt"
      height="29.03pt"
      viewBox="0 0 800 387"
      class="color"
    >
      <title>Top bar logo</title>
      <g
        transform="translate(0.000000,387.000000) scale(0.100000,-0.100000)"
        fill="#d10737"
        stroke="none"
      >
        <path
          d="M2970 3859 c-33 -13 -60 -39 -60 -57 0 -8 18 -55 41 -105 78 -175 37
    -217 -306 -312 -240 -66 -235 -64 -235 -120 0 -64 50 -155 86 -155 7 0 99 41
    206 91 106 50 210 98 232 105 54 19 105 17 143 -6 32 -19 42 -44 89 -212 3 -9
    14 -20 24 -23 26 -8 96 14 116 36 16 18 15 24 -20 100 -20 44 -36 91 -36 103
    0 50 61 77 250 113 76 14 147 17 355 17 281 0 388 -13 587 -69 48 -14 94 -25
    102 -25 9 0 20 16 26 42 24 87 106 131 205 109 68 -15 142 -65 167 -111 21
    -41 23 -105 3 -140 -8 -14 -14 -30 -15 -37 0 -11 138 -103 156 -103 5 0 29 20
    52 45 46 49 148 104 325 176 59 23 107 46 107 50 0 9 -59 47 -185 117 -580
    324 -1206 407 -1870 247 -77 -19 -180 -48 -229 -64 -50 -17 -102 -31 -116 -31
    -41 0 -66 36 -85 123 -9 43 -24 84 -32 92 -16 17 -47 18 -83 4z"
        />
        <path
          d="M2951 2952 c-57 -29 -58 -38 -11 -142 48 -107 50 -153 8 -197 -42
    -46 -99 -67 -373 -143 -85 -23 -155 -49 -162 -58 -7 -9 -13 -32 -13 -51 0 -39
    45 -128 71 -144 13 -8 59 9 200 76 259 121 275 127 330 127 75 0 102 -30 136
    -152 l27 -98 41 0 c44 0 105 31 105 53 0 8 -16 48 -35 90 -19 42 -35 85 -35
    95 0 38 52 67 159 90 174 37 279 45 521 39 234 -6 353 -23 545 -77 41 -12 79
    -19 84 -16 5 3 11 20 15 37 13 69 81 119 161 119 79 0 179 -59 214 -127 16
    -31 14 -97 -4 -140 -12 -29 -13 -38 -3 -45 7 -4 44 -27 81 -50 l68 -41 45 47
    c55 57 209 136 422 217 l27 10 -55 35 c-324 206 -664 333 -1047 391 -168 25
    -508 22 -693 -6 -173 -26 -345 -67 -493 -115 -60 -20 -116 -36 -126 -36 -38 0
    -60 33 -82 120 -11 47 -25 91 -31 98 -15 17 -57 15 -97 -6z"
        />
        <path
          d="M2933 2050 c-18 -11 -33 -28 -33 -38 0 -10 18 -58 40 -107 22 -50 40
    -97 40 -105 0 -8 -9 -34 -20 -58 -17 -37 -29 -47 -82 -71 -35 -16 -144 -51
    -243 -79 -252 -71 -235 -62 -235 -120 0 -54 32 -126 66 -148 17 -12 38 -5 187
    66 252 120 286 133 344 134 76 0 104 -29 133 -142 27 -101 34 -112 69 -112 35
    1 111 36 111 52 0 8 -16 46 -35 87 -69 144 -46 167 210 211 299 51 686 28 988
    -59 l68 -20 10 27 c39 101 79 132 169 132 155 0 274 -141 215 -254 -9 -16 -14
    -35 -11 -42 2 -6 39 -33 81 -59 l76 -48 44 49 c47 54 134 102 325 180 57 24
    106 47 107 51 2 5 -48 39 -109 75 -325 193 -672 312 -1034 354 -105 12 -188
    15 -339 11 -286 -8 -504 -46 -789 -137 -71 -22 -137 -38 -148 -35 -28 9 -45
    42 -63 120 -25 111 -22 105 -68 105 -24 0 -56 -9 -74 -20z"
        />
        <path
          d="M2635 861 c-92 -24 -161 -87 -195 -177 -71 -190 -3 -404 150 -473 52
    -24 159 -28 222 -9 78 23 148 109 172 211 6 26 5 27 -38 27 -43 0 -45 -2 -56
    -37 -27 -85 -90 -133 -172 -133 -90 1 -173 66 -198 156 -18 65 -9 199 18 251
    43 82 139 126 228 104 63 -16 88 -34 115 -86 23 -42 26 -45 66 -45 50 0 52 6
    19 75 -25 55 -71 100 -125 122 -41 17 -163 26 -206 14z"
        />
        <path
          d="M5507 856 c-134 -37 -216 -181 -204 -356 9 -137 67 -239 162 -285 57
    -27 182 -33 241 -10 83 31 134 92 159 185 l13 50 -44 0 c-34 0 -46 -4 -50 -17
    -15 -53 -54 -110 -90 -131 -55 -32 -145 -27 -202 10 -59 40 -94 116 -95 210
    -2 101 18 162 67 214 94 96 264 73 312 -43 13 -30 18 -33 59 -33 44 0 45 1 39
    28 -17 68 -62 126 -126 158 -62 32 -169 41 -241 20z"
        />
        <path
          d="M0 525 l0 -325 240 0 240 0 0 40 0 40 -195 0 -195 0 0 110 0 110 180
    0 180 0 0 40 0 40 -180 0 -180 0 0 95 0 95 195 0 195 0 0 40 0 40 -240 0 -240
    0 0 -325z"
        />
        <path d="M7760 805 l0 -45 40 0 40 0 0 45 0 45 -40 0 -40 0 0 -45z" />
        <path d="M7920 525 l0 -325 40 0 40 0 0 325 0 325 -40 0 -40 0 0 -325z" />
        <path
          d="M4480 735 l0 -65 -30 0 c-27 0 -30 -3 -30 -30 0 -26 3 -30 29 -30
    l29 0 4 -185 c3 -174 4 -186 25 -206 16 -16 38 -24 77 -27 l56 -4 0 41 c0 37
    -2 42 -19 36 -13 -4 -27 1 -41 15 -19 19 -20 30 -18 173 l3 152 38 3 c33 3 37
    6 37 32 0 26 -4 29 -37 32 l-38 3 -3 63 -3 62 -39 0 -40 0 0 -65z"
        />
        <path
          d="M651 679 c-55 -17 -79 -38 -97 -85 -14 -37 -15 -51 -5 -84 16 -53 52
    -76 159 -101 123 -28 162 -69 112 -119 -42 -42 -149 -40 -187 5 -13 14 -23 33
    -23 41 0 10 -12 14 -41 14 l-42 0 6 -35 c13 -82 79 -125 193 -125 104 0 175
    47 190 125 18 99 -23 132 -233 185 -56 14 -72 43 -46 78 41 55 148 50 178 -8
    13 -25 22 -30 50 -30 30 0 35 3 35 23 0 33 -35 84 -71 103 -40 20 -132 27
    -178 13z"
        />
        <path
          d="M1068 679 c-69 -20 -105 -78 -98 -159 5 -59 47 -87 173 -115 110 -24
    142 -62 98 -116 -14 -18 -30 -23 -77 -26 -71 -5 -103 10 -124 54 -13 28 -20
    33 -52 33 -45 0 -48 -13 -19 -76 28 -62 87 -87 191 -83 60 3 85 9 106 25 44
    32 66 68 71 115 8 83 -31 117 -176 154 -49 12 -94 25 -100 29 -17 11 -13 61 7
    79 46 41 152 24 172 -28 8 -21 16 -25 50 -25 39 0 40 1 34 28 -8 38 -36 74
    -73 95 -37 22 -131 30 -183 16z"
        />
        <path
          d="M1526 675 c-88 -31 -136 -114 -136 -234 0 -164 74 -251 214 -251 102
    0 180 55 201 143 7 26 6 27 -32 27 -34 0 -41 -4 -58 -36 -22 -42 -60 -64 -110
    -64 -72 0 -121 47 -127 122 l-3 33 174 3 174 2 -7 62 c-4 34 -16 78 -26 99
    -47 92 -158 132 -264 94z m129 -69 c37 -16 75 -68 75 -103 0 -23 -2 -23 -130
    -23 l-130 0 6 28 c21 90 98 132 179 98z"
        />
        <path
          d="M3165 675 c-52 -18 -110 -83 -124 -139 -14 -56 -14 -149 -1 -197 16
    -57 51 -102 100 -127 38 -20 57 -23 122 -20 67 3 81 7 115 34 60 48 87 112 87
    208 0 135 -50 217 -149 245 -53 14 -101 13 -150 -4z m151 -80 c40 -26 56 -62
    62 -135 9 -126 -40 -200 -131 -200 -88 0 -132 60 -132 180 0 57 5 84 21 110
    37 65 118 86 180 45z"
        />
        <path
          d="M4179 677 c-20 -8 -50 -24 -68 -36 l-31 -22 0 25 c0 24 -4 26 -40 26
    l-40 0 0 -235 0 -235 40 0 40 0 0 150 c0 109 4 160 14 185 30 72 139 100 187
    49 23 -25 24 -31 27 -205 l3 -179 40 0 41 0 -4 193 c-3 207 -9 232 -59 270
    -34 25 -104 31 -150 14z"
        />
        <path
          d="M6068 681 c-104 -34 -148 -108 -148 -251 0 -158 76 -240 220 -240
    140 0 212 88 211 257 -1 146 -70 230 -198 239 -32 2 -70 0 -85 -5z m138 -86
    c47 -30 59 -63 59 -155 0 -62 -5 -94 -18 -117 -45 -84 -179 -84 -224 0 -26 48
    -25 180 2 227 38 65 119 86 181 45z"
        />
        <path
          d="M7045 678 c-16 -6 -45 -21 -62 -35 l-33 -24 0 25 c0 24 -4 26 -40 26
    l-40 0 0 -235 0 -235 40 0 40 0 0 159 c0 148 1 160 23 192 43 63 127 80 172
    34 25 -24 25 -26 25 -205 l0 -180 46 0 46 0 -3 193 c-4 171 -6 196 -24 227
    -36 61 -114 85 -190 58z"
        />
        <path
          d="M7450 678 c-94 -26 -143 -106 -144 -232 -1 -45 5 -98 13 -119 17 -50
    67 -106 108 -123 42 -18 135 -18 176 0 57 24 107 93 107 147 0 15 -7 19 -39
    19 -36 0 -40 -3 -46 -30 -4 -16 -21 -41 -38 -56 -27 -22 -38 -25 -79 -22 -84
    9 -118 61 -118 182 0 71 28 136 67 157 14 8 46 14 71 13 38 -1 50 -6 74 -33
    15 -17 28 -40 28 -51 0 -17 7 -20 40 -20 45 0 51 15 26 73 -37 86 -139 126
    -246 95z"
        />
        <path
          d="M1886 561 c41 -60 74 -114 74 -118 0 -5 -29 -51 -65 -101 -36 -51
    -72 -103 -81 -117 l-16 -25 50 0 50 0 58 90 c31 49 61 87 64 85 4 -3 31 -43
    61 -90 l54 -85 47 0 c27 0 48 3 48 6 0 3 -36 58 -81 121 l-81 115 69 101 c37
    56 72 107 76 114 6 10 -4 13 -41 13 l-49 0 -53 -81 -53 -80 -51 80 -51 80 -52
    0 -51 1 74 -109z"
        />
        <path
          d="M3530 493 c0 -98 5 -195 10 -215 6 -20 25 -49 42 -63 29 -25 38 -27
    99 -23 51 3 75 10 107 31 51 33 52 33 52 2 0 -22 4 -25 35 -25 l35 0 0 235 0
    235 -40 0 -40 0 0 -164 c0 -152 -1 -165 -22 -191 -24 -31 -74 -55 -113 -55
    -16 0 -38 12 -56 29 l-29 29 0 176 0 176 -40 0 -40 0 0 -177z"
        />
        <path
          d="M4690 664 c0 -4 38 -112 85 -241 l85 -234 -18 -42 c-24 -54 -45 -70
    -82 -65 -29 4 -30 3 -30 -34 l0 -38 49 0 c54 0 91 22 116 70 14 27 215 575
    215 586 0 3 -19 4 -42 2 l-41 -3 -63 -185 c-54 -162 -63 -182 -71 -160 -6 14
    -34 98 -64 188 l-54 162 -42 0 c-24 0 -43 -3 -43 -6z"
        />
        <path
          d="M6410 493 c0 -200 8 -241 51 -277 25 -22 41 -26 90 -26 58 0 128 24
    144 50 11 18 25 10 25 -15 0 -22 4 -25 35 -25 l35 0 0 235 0 235 -40 0 -40 0
    0 -164 c0 -152 -1 -165 -22 -191 -24 -31 -74 -55 -113 -55 -16 0 -38 12 -56
    29 l-29 29 0 176 0 176 -40 0 -40 0 0 -177z"
        />
        <path d="M7760 435 l0 -235 40 0 40 0 0 235 0 235 -40 0 -40 0 0 -235z" />
      </g>
    </svg>
  </div>
  <div class="flex flex-row justify-center">
    @if (store.isMenuBarVisible()) {
      <fa-icon
        (click)="toggleSidebar.emit()"
        class="fa-xl pt-2.5 text-gray-600 lg:hidden cursor-pointer"
        [icon]="['fas', 'bars']"
      ></fa-icon>
      <div class="flex gap-6 py-0.5">
        <ul class="hidden gap-6 py-0.5 lg:flex" cdkMenuBar>
          @for (item of data().items.data; track $index) {
            <li
              class="flex cursor-pointer flex-col justify-center text-sm font-semibold"
              cdkMenuItem
              [cdkMenuTriggerFor]="submenu"
              cdkOverlayOrigin
              #trigger="cdkOverlayOrigin"
            >
              <div class="flex items-center gap-0.5 hover:text-brand-700">
                <div>{{ item.attributes.title }}</div>
                <div class="w-5">
                  <fa-icon
                    class="fa-sm pl-1.5 pt-0.5"
                    [icon]="['fas', 'caret-down']"
                  ></fa-icon>
                </div>
              </div>
              <ng-template
                #submenu
                cdkConnectedOverlay
                [cdkConnectedOverlayOrigin]="trigger"
              >
                <div
                  cdkMenu
                  class="flex flex-col rounded-md bg-white shadow-lg"
                >
                  @for (
                    subItem of item.attributes.children.data;
                    track $index
                  ) {
                    @if (subItem.attributes.isExternal) {
                      <a
                        [href]="subItem.attributes.url"
                        [target]="subItem.attributes.target"
                        class="cursor-pointer py-3 pl-6 pr-16 text-sm font-semibold hover:bg-gray-50 hover:text-brand-700"
                      >
                        {{ subItem.attributes.title }}</a
                      >
                    } @else {
                      <a
                        [routerLink]="subItem.attributes.url"
                        class="cursor-pointer py-3 pl-6 pr-16 text-sm font-semibold hover:bg-gray-50 hover:text-brand-700"
                      >
                        {{ subItem.attributes.title }}</a
                      >
                    }
                  }
                </div>
              </ng-template>
            </li>
          }
        </ul>
      </div>
    }
    @if (store.isHelpVisible()) {
      <nav-help-slide-in [faqTag]="store.registrationStep()" />
    }
  </div>
</div>
