<section class="rounded-md bg-gray-50">
    <div class="mx-auto max-w-screen-xl px-4 pt-10 pb-8 md:px-6">
        <div class="flex flex-col gap-4 px-4">
            <h4 class="text-center">{{ data()?.title }} </h4>
            <p class="text-center text-md text-gray-600">
                {{ data()?.subTitle }}
            </p>
        </div>

        <div class="mt-6 flex flex-col gap-8 sm:gap-12">
            <div class="flex flex-col gap-4">
                <ui-text-input class="flex-1" [control]="form.controls.firstNameControl" [label]="data()?.firstNameLabel" />
                <ui-text-input class="flex-1" [control]="form.controls.lastNameControl" [label]="data()?.lastNameLabel" />
                <ui-text-input [control]="form.controls.emailControl" label="Email" [label]="data()?.emailLabel" />
                <div class="flex flex-1 flex-col gap-2">
                    <label><span class="text-sm font-medium text-gray-700">{{ data()?.messageLabel }}</span></label>
                    <div class="relative">
                        <textarea id="comments" [ngClass]="{
                            '!pr-7 !ring-1 !ring-error-border':
                              (form.controls.messageControl.touched || form.controls.messageControl.dirty) && form.controls.messageControl.invalid,
                          }"
                            class="p-3 block w-full rounded-md border border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
                            rows="4" [placeholder]="data()?.messagePlaceholder" [formControl]="form.controls.messageControl"></textarea>
                    </div>
                </div>

                <div class="mt-4 flex justify-end gap-4">
                    <button class="primary w-full px-3 py-2" [disabled]="form.disabled" (click)="sendMessage()">
                        {{ data()?.buttonLabel }}
                    </button>
                </div>
                <div class="flex w-full items-start gap-1 text-sm"
                 [ngClass]="{ 'text-brand-600': state === 'sent', 'text-error-text': state === 'error' }">
                    @switch (state) {
                        @case ('sent') {
                            <fa-icon [icon]="['fas', 'check']"></fa-icon>
                            <span>{{ data()?.succesText }}</span>
                        }
                        @case ('error') {
                            <fa-icon [icon]="['fas', 'circle-exclamation']"></fa-icon>
                            <span>{{ data()?.errorText }}</span>
                        }
                    }
                </div>
            </div>
        </div>
    </div>
</section>
